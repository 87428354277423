export default {
    // Endpoints
    loginEndpoint: 'auth/login',
    registerEndpoint: '/jwt/register',
    refreshEndpoint: '/jwt/refresh-token',
    logoutEndpoint: '/jwt/logout',
    requestResetPassword: 'auth/request_password_reset',
    resetPassword: 'auth/reset_password',

    //Dashboard
    billing: 'billings/',
    billingDetail: 'billings/detail/',

    //Tier
    tierList: 'internal-tiers',
    changeTier: 'change_tier',

    //Stats
    stats: 'stats',

    //Configure / Settings
    configure: 'configurations',
    helpText: 'helptexts',

    //Configure bookeepcriteria
    bookKeep: 'configurations_bookkeep_criteria',

    //regularCriteria
    regularCriteria: 'configurations_sendregular_criteria',
    
    //NoxCriteria
    noxCriteria: 'configurations_sendnox_criteria',

    //Subscription Packages
    packages: 'internal-packages/',
    subscriptions: 'subscriptions/',

    //Faq
    faqCategories: 'faq-categories',
    listfaqByCategories: 'faq',

    //Failure / error-log
    failure: 'failures',

    //Blogs
    listBlog: 'blogs',
    blogCategories: 'blog-categories',

    // This will be prefixed in authorization header with token
    // e.g. Authorization: Bearer <token>
    tokenType: 'token',

    // Value of this property will be used as key to store JWT token in storage
    storageTokenKeyName: 'accessToken',
    storageRefreshTokenKeyName: 'refreshToken',
};
