import jwtDefaultConfig from './jwtDefaultConfig';

export default class JwtService {
    // Will be used by this service for making API calls
    axiosIns = null;

    // jwtConfig <= Will be used by this service
    jwtConfig = {...jwtDefaultConfig};

    // For Refreshing Token
    isAlreadyFetchingAccessToken = false;

    // For Refreshing Token
    subscribers = [];

    constructor(axiosIns, jwtOverrideConfig) {
        this.axiosIns = axiosIns;
        this.jwtConfig = {...this.jwtConfig, ...jwtOverrideConfig};

        // Request Interceptor
        this.axiosIns.interceptors.request.use(
            config => {
                // Get token from localStorage
                const accessToken = this.getToken();

                // If token is present add it to request's Authorization Header
                if (accessToken) {
                    // eslint-disable-next-line no-param-reassign
                    config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`;
                }
                return config;
            },
            error => Promise.reject(error)
        );

        // Add request/response interceptor
        this.axiosIns.interceptors.response.use(
            response => response,
            error => {
                // const { config, response: { status } } = error
                const {config, response} = error;
                const originalRequest = config;

                // if (status === 401) {
                if (response && response.status === 401) {
                    const lang =
                        localStorage.getItem('lang') == 'sv'
                            ? error.response.data.message
                            : error.response.data.eng_message;
                    return {
                        status: 401,
                        message: lang || error.response.data.detail,
                    };
                }
                return Promise.reject(error);
            }
        );
    }

    onAccessTokenFetched(accessToken) {
        this.subscribers = this.subscribers.filter(callback =>
            callback(accessToken)
        );
    }

    addSubscriber(callback) {
        this.subscribers.push(callback);
    }

    getToken() {
        return localStorage.getItem(this.jwtConfig.storageTokenKeyName);
    }

    getRefreshToken() {
        return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName);
    }

    setToken(value) {
        localStorage.setItem(this.jwtConfig.storageTokenKeyName, value);
    }

    setRefreshToken(value) {
        localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value);
    }

    login(...args) {
        return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args);
    }

    requestResetPassword(...args) {
        return this.axiosIns.post(this.jwtConfig.requestResetPassword, ...args);
    }

    resetUpdatePassword(...args) {
        return this.axiosIns.post(this.jwtConfig.resetPassword, ...args);
    }

    register(...args) {
        return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args);
    }

    packages(...args) {
        return this.axiosIns.get(this.jwtConfig.packages, ...args);
    }

    subscription(...args) {
        return this.axiosIns.get(this.jwtConfig.subscriptions, ...args);
    }

    updateSubscription(uuid, ...args) {
        return this.axiosIns.put(
            `${this.jwtConfig.subscriptions}${uuid}`,
            ...args
        );
    }

    cancelSubscription(uuid, ...args) {
        return this.axiosIns.put(
            `${this.jwtConfig.subscriptions}${uuid}/cancel`,
            ...args
        );
    }
    changePaymentPlan(args) {
        return this.axiosIns.put(
            `${this.jwtConfig.subscriptions}${args.id}/change_plan`,
            args.payment_plan
        );
    }

    stats(...args) {
        return this.axiosIns.get(this.jwtConfig.stats, ...args);
    }

    billings(args) {
        return args
            ? this.axiosIns.get(this.jwtConfig.billing, args)
            : this.axiosIns.get(this.jwtConfig.billing);
    }

    billingsDetail() {
        return this.axiosIns.get(this.jwtConfig.billingDetail);
    }

    updateBillingsDetail(...args) {
        return this.axiosIns.put(this.jwtConfig.billingDetail, ...args);
    }

    configure(...args) {
        return this.axiosIns.get(this.jwtConfig.configure, ...args);
    }
    updateConfigure(uuid, ...args) {
        return this.axiosIns.put(
            `${this.jwtConfig.configure}/${uuid}`,
            ...args
        );
    }

    getBookKeepList() {
        return this.axiosIns.get(this.jwtConfig.bookKeep);
    }

    addBookKeepList(...args) {
        return this.axiosIns.post(this.jwtConfig.bookKeep, ...args);
    }
    updateBookKeepList(uuid, ...args) {
        return this.axiosIns.put(`${this.jwtConfig.bookKeep}/${uuid}`, ...args);
    }
    deleteBookKeepList(uuid) {
        return this.axiosIns.delete(`${this.jwtConfig.bookKeep}/${uuid}`);
    }

    getRegularCriteriaList() {
        return this.axiosIns.get(this.jwtConfig.regularCriteria);
    }
    addRegularCriteriaList(...args) {
        return this.axiosIns.post(this.jwtConfig.regularCriteria, ...args);
    }
    updateRegularCriteriaList(uuid, ...args) {
        return this.axiosIns.put(
            `${this.jwtConfig.regularCriteria}/${uuid}`,
            ...args
        );
    }
    deleteRegularCriteriaList(uuid) {
        return this.axiosIns.delete(
            `${this.jwtConfig.regularCriteria}/${uuid}`
        );
    }

    getNoxCriteriaList() {
        return this.axiosIns.get(this.jwtConfig.noxCriteria);
    }
    addNoxCriteriaList(...args) {
        return this.axiosIns.post(this.jwtConfig.noxCriteria, ...args);
    }
    updateNoxCriteriaList(uuid, ...args) {
        return this.axiosIns.put(
            `${this.jwtConfig.noxCriteria}/${uuid}`,
            ...args
        );
    }
    deleteNoxCriteriaList(uuid) {
        return this.axiosIns.delete(`${this.jwtConfig.noxCriteria}/${uuid}`);
    }

    listHelpText(...args) {
        return this.axiosIns.get(this.jwtConfig.helpText, ...args);
    }

    billingDownload(id) {
        return this.axiosIns.get(`${this.jwtConfig.billing}${id}/download`);
    }

    faqCategories(...args) {
        return this.axiosIns.get(this.jwtConfig.faqCategories, ...args);
    }

    faqList(...args) {
        if (args) {
            return this.axiosIns.get(
                `${this.jwtConfig.listfaqByCategories}/${args}`
            );
        } else
            return this.axiosIns.get(
                this.jwtConfig.listfaqByCategories,
                ...args
            );
    }

    failuresList(...args) {
        return this.axiosIns.get(this.jwtConfig.failure, ...args);
    }

    tierList() {
        return this.axiosIns.get(this.jwtConfig.tierList);
    }

    changeTier(...args) {
        return this.axiosIns.post(this.jwtConfig.changeTier, ...args);
    }

    blogsList(...args) {
        if (args) {
            return this.axiosIns.get(this.jwtConfig.listBlog, ...args);
        } else return this.axiosIns.get(this.jwtConfig.listBlog);
    }

    blogDetails(uuid, ...args) {
        return this.axiosIns.get(`${this.jwtConfig.listBlog}/${uuid}`, ...args);
    }

    blogCategories() {
        return this.axiosIns.get(this.jwtConfig.blogCategories);
    }

    refreshToken() {
        return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
            refreshToken: this.getRefreshToken(),
        });
    }
}
