import Vue from 'vue';
import {ToastPlugin, ModalPlugin} from 'bootstrap-vue';
import VueCompositionAPI from '@vue/composition-api';
import {BootstrapVue} from 'bootstrap-vue';

import i18n from '@/libs/i18n';
import router from './router';
import store from './store';
import App from './App.vue';

import mdiVue from 'mdi-vue/v2';
import * as mdijs from '@mdi/js';

// Global Components
import './global-components';

// 3rd party plugins
import '@axios';
import '@/libs/portal-vue';
import '@/libs/toastification';
import '@/libs/vue-select';

Vue.use(mdiVue, {
    icons: mdijs,
});

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);

// Composition API
Vue.use(VueCompositionAPI);

// Global Mixin
Vue.mixin({
    computed: {
        swedishLang() {
            return this.defaultLang == 'sv';
        },
        defaultLang() {
            return localStorage.getItem('lang');
        },
    },
});

// import core styles
require('@core/scss/core.scss');

// import assets styles
require('@/assets/scss/style.scss');

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
}).$mount('#app');
