import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);
import {isUserLoggedIn} from '@/auth/utils';
import useJwt from '@/auth/jwt/useJwt';
import i18n from '@/libs/i18n';

const router = new VueRouter({
    mode: 'history',
    // base: process.env.BASE_URL,
    // scrollBehavior() {
    //     return {x: 0, y: 0};
    // },
    routes: [
        {
            path: '/',
            name: 'home',
            component: () => import('@/views/Home.vue'),
            meta: {
                layout: 'fullIndex',
                defaultRoute: true,
            },
        },
        {
            path: '/about',
            name: 'about',
            component: () => import('@/views/About.vue'),
            meta: {
                layout: 'fullIndex',
                defaultRoute: true,
            },
        },
        {
            path: '/contact',
            name: 'contact',
            component: () => import('@/views/Contact.vue'),
            meta: {
                layout: 'fullIndex',
                defaultRoute: true,
            },
        },
        {
            path: '/blog',
            name: 'blog',
            component: () => import('@/views/Blog.vue'),
            meta: {
                layout: 'fullIndex',
                defaultRoute: true,
            },
        },
        {
            path: '/blog/:slug',
            name: 'blogdetail',
            component: () => import('@/views/BlogDetail.vue'),
            meta: {
                blogDetails: true,
                layout: 'fullIndex',
                defaultRoute: true,
            },
        },
        {
            path: '/dashboard',
            name: 'dashboard',
            component: () => import('@/views/Dashboard.vue'),
            meta: {
                pageTitle: 'Sidebar.Dashboard',
                redirectIfLoggedIn: true,
                breadcrumb: [
                    {
                        text: 'Sidebar.Dashboard',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/dashboard/settings',
            name: 'settings',
            component: () => import('@/views/Settings.vue'),
            meta: {
                pageTitle: 'Sidebar.Settings',
                redirectIfLoggedIn: true,
                breadcrumb: [
                    {
                        text: 'Sidebar.Settings',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/dashboard/subscription',
            name: 'subscription',
            component: () => import('@/views/Subscription.vue'),
            meta: {
                pageTitle: 'Sidebar.Subscription',
                redirectIfLoggedIn: true,
                breadcrumb: [
                    {
                        text: 'Sidebar.Subscription',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/dashboard/billing',
            name: 'billing',
            component: () => import('@/views/BillingPage.vue'),
            meta: {
                pageTitle: 'Sidebar.BillingPage',
                redirectIfLoggedIn: true,
                breadcrumb: [
                    {
                        text: 'Sidebar.BillingPage',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/dashboard/faq',
            name: 'faq',
            component: () => import('@/views/Faq.vue'),
            meta: {
                pageTitle: 'Sidebar.Faq',
                redirectIfLoggedIn: true,
                breadcrumb: [
                    {
                        text: 'Sidebar.Faq',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/dashboard/error-log',
            name: 'errorLog',
            component: () => import('@/views/ErrorLog.vue'),
            meta: {
                pageTitle: 'Sidebar.ErrorLog',
                redirectIfLoggedIn: true,
                breadcrumb: [
                    {
                        text: 'Sidebar.ErrorLog',
                        active: true,
                        // icon: '',
                    },
                ],
            },
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('@/views/Login.vue'),
            meta: {
                mainRoute: true,
                defaultRoute: true,
                layout: 'full',
            },
        },
        {
            path: '/forgot-password',
            name: 'forgot-password',
            component: () => import('@/views/ForgotPassword.vue'),
            meta: {
                mainRoute: true,
                defaultRoute: true,
                layout: 'full',
            },
        },
        {
            path: '/reset-password',
            name: 'reset-password',
            component: () => import('@/views/ResetPassword.vue'),
            meta: {
                resetPassword: true,
                defaultRoute: true,
                mainRoute: true,
                layout: 'full',
            },
        },
        {
            path: '/error-404',
            name: 'error-404',
            component: () => import('@/views/error/Error404.vue'),
            meta: {
                layout: 'full',
                defaultRoute: true,
            },
        },
        {
            path: '*',
            redirect: 'error-404',
        },
    ],
});

router.beforeEach((to, from, next) => {
    const lang = localStorage.getItem('lang');

    if (!lang) localStorage.setItem('lang', 'sv');
    //Main lang, Language for home / blog
    localStorage.setItem('mainLang', 'sv');

    const isLoggedIn = isUserLoggedIn();

    if (to.meta.redirectIfLoggedIn) {
        if (!isLoggedIn) {
            next('/login');
        } else {
            useJwt.stats().then(res => {
                if (res.status == 401) {
                    next({
                        name: 'login',
                        query: {
                            q: 'tokenExpired',
                        },
                    });
                    localStorage.removeItem(
                        useJwt.jwtConfig.storageTokenKeyName
                    );
                    localStorage.removeItem(
                        useJwt.jwtConfig.storageRefreshTokenKeyName
                    );

                    // Remove userData from localStorage
                    localStorage.removeItem('userData');
                }
            });
        }
    } else if (to.meta.mainRoute) {
        if (!isLoggedIn) {
            next();
        } else {
            if (!to.query.q) next('/dashboard');
        }
    }

    if (to.meta.defaultRoute) {
        i18n.locale = localStorage.getItem('mainLang');
    }

    if (to.meta.resetPassword) {
        //resetPassword
        if (to.query.token) {
            localStorage.setItem('resetToken', JSON.stringify(to.query.token));
        } else next('/login');
    }

    return next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach((to, from, failure) => {
    //Check
    const lang = localStorage.getItem('lang');

    if (!lang) localStorage.setItem('lang', 'sv');

    if (to.meta.blogDetails) {
        useJwt
            .blogDetails(to.params.slug, {
                transformRequest: (data, headers) => {
                    delete headers.Authorization;
                    return data;
                },
            })
            .catch(err => {
                if (err.response) router.push({name: 'error-404'});
            });
    }

    // Remove initial loading
    const appLoading = document.getElementById('loading-bg');
    if (appLoading) appLoading.style.display = 'none';

    // return next();
});

export default router;
